/* import jQuery from "jquery";
window.$ = window.jQuery = jQuery; */
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination} from "swiper";

const swiper = new Swiper(".bigteaserswiper", {
	modules: [Navigation, Pagination],
	loop: true,
	speed: 1150,
	autoplay: {
        delay: 5000,
		pauseOnMouseEnter: true,
		disableOnInteraction: false,
    },	
	grabCursor: false,
	pagination: {
		el: '.bigteaserswiper .swiper-pagination',
		clickable:true,
	},	
	navigation: {
		nextEl: '.bigteaserswiper .swiper-button-next',
		prevEl: '.bigteaserswiper .swiper-button-prev',
	},
	slidesPerView: 1,
	spaceBetween: 0,
});

		









