/* import jQuery from "jquery";
window.$ = window.jQuery = jQuery; */
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination} from "swiper";

const swiper = new Swiper(".heroswiper", {
	modules: [Navigation, Pagination],
	loop: true,
	speed: 1150,
	autoplay: {
        delay: 5000,
		pauseOnMouseEnter: true,
		disableOnInteraction: false,
    },
	grabCursor: false,
	pagination: {
		el: '.heroswiper .swiper-pagination',
		clickable:true,
	}
});

		









